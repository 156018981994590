import {
  BrowserRouter,
  Link,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import React, { useRef, useState } from "react";
import "./App.scss";
import DefaultPage from "./pages/default";
import {
  RINGBA_STORAGE_KEYS,
  sessionStorageKeys,
  useEventID,
  useGeneratorQuery,
  useRingba,
} from "wecall-config-lib";
import { useEffect } from "react";
// import trustseal from "../src/assets/trust-seal.png";
// import arrow from "../src/assets/arrow.svg";
import axios from "axios";
import TermsAndCondition from "./pages/termsAndCondtion";
import Privacy from "./pages/privacy";
import Lander3, {
  Lander3CTA,
  Lander3Congrats,
  Lander3Income,
  Lander3MedicareMedicaid,
} from "./pages/lander-3";
import Lander4, {
  Lander4CTA,
  Lander4Congrats,
  Lander4Income,
  Lander4MedicareMedicaid,
} from "./pages/lander-4";
import QuizLander from "./pages/quizLander";
import { PropagateLoader } from "react-spinners";
import {
  CTAQuizLander,
  CongratsQuizLander,
  IncomeQuizLander,
  MedicareMedicaidQuizLander,
} from "./pages/quiz";
import { useInitRingba } from "wecall-config-lib";
window._rgba_tags = window._rgba_tags || [];

const routes = {
  congrats: "congratsVisited",
  income: "incomeVisited",
  medicareMedicaid: "medicareMedicaidVisited",
  lander: "landerVisited",
};

export const ROUTES = {
  congrats: "congrats",
  income: "income",
  medicareMedicaid: "medicare-medicaid",
  healthInsurance: "health-insurance",
  phoneNumber: "phone-number",
};
function GetRingbaNumber({ setNumber }) {
  const [ringbaUser, setRingbaUser] = useState(
    window.domain_settings.rinbaUser
  );
  const testRingba = window.domain_settings.rinbaUser;

  const { number } = useInitRingba({
    ringbaKey: {
      key: testRingba.key,
      number: testRingba.number,
      user: testRingba.user,
    },
  });
  useEffect(() => {
    setNumber(number);
  }, [number]);
  return <></>;
}
function Congrats({ headline }) {
  const navigate = useNavigate();
  const { getEventId } = useEventID();
  const generatorQuery = useGeneratorQuery();
  const [number, setNumber] = useState();

  const triggerContactEvent = () => {
    window.fbcFunc("track", "Contact", {
      eventID: getEventId(),
    });
  };

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(routes.medicareMedicaid))
      return navigate({
        pathname: "../" + ROUTES.medicareMedicaid,
        search: generatorQuery.get(),
      });
    sessionStorage.setItem(routes.congrats, true);
  }, []);

  return (
    <>
      <GetRingbaNumber setNumber={setNumber} />

      <div className="question-item">
        <div className="congrats">
          <h2>{headline}</h2>
          <p>Space is limited. Tap the button below to call and claim.</p>
          <a
            onClick={triggerContactEvent}
            href={
              "tel:" +
              (window.pnumber || window.domain_settings.rinbaUser?.number)
            }
            className="call_now"
          >
            {window.pnumber || window.domain_settings.rinbaUser?.number}
          </a>
          <p>
            This program has extremely limited capacity, so call us in the next{" "}
            <Timer /> to claim your subsidy before it's given to another
            applicant in line.
          </p>
          <img loading="lazy" src={"/assets/trust-seal.png"} alt=""></img>
        </div>
      </div>
    </>
  );
}

function MedicareMedicaid({ headline, nextPageRoute }) {
  const generatorQuery = useGeneratorQuery();
  const { storeRgbaData } = useRingba();
  const navigate = useNavigate();

  const setValues = (value) => {
    sessionStorage.setItem(RINGBA_STORAGE_KEYS.medicareMedicaid, value);
    window._rgba_tags = window._rgba_tags || [];
    window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.medicareMedicaid]: value });
  };

  const externalRedirectLink = window.domain_settings.medicareMedicAidYesLink;

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(routes.income))
      return navigate({
        pathname: "../" + ROUTES.income,
        search: generatorQuery.get(),
      });
    sessionStorage.setItem(routes.medicareMedicaid, true);
  }, []);
  return (
    <>
      <div className="question-wrapper">
        <div className="headline-wrapper">
          <h1>{headline}</h1>
        </div>
        <div className="question-item">
          <div className="question">
            <h2>Are you on Medicare or Medicaid?</h2>

            <Link
              onClick={() => setValues("yes")}
              id="medicare_medicaid_yes"
              to={externalRedirectLink}
            >
              Yes
            </Link>

            <Link
              onClick={() => setValues("no")}
              id="medicare_medicaid_no"
              to={{ pathname: nextPageRoute, search: generatorQuery.get() }}
            >
              No
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

function Income({ headline, nextPageRoute }) {
  const generatorQuery = useGeneratorQuery();
  const { storeRgbaData } = useRingba();

  const setValues = (value) => {
    // sessionStorage.setItem(RINGBA_STORAGE_KEYS.income, value);
    // storeRgbaData(RINGBA_STORAGE_KEYS.income, value);
    sessionStorage.setItem("healthInsurance", value);

    window._rgba_tags = window._rgba_tags || [];
    // window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.income]: value });
    window._rgba_tags.push({ ["healthInsurance"]: value });
  };

  const navigate = useNavigate();

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(routes.lander))
      return navigate({ pathname: "../", search: generatorQuery.get() });
    sessionStorage.setItem(routes.income, true);
  }, []);
  return (
    <>
      <div className="question-wrapper">
        <div className="headline-wrapper">
          <h1>{headline}</h1>
        </div>
        <div className="question-item">
          <div className="question">
            {/* <h2>Do you make less than $50k/Yr?</h2> */}
            <h2>Do you have health insurance?</h2>

            <Link
              // onClick={() => setValues("- - 50")}
              onClick={() => setValues("yes")}
              to={{
                pathname: "../medicare-medicaid",
                search: generatorQuery.get(),
              }}
            >
              Yes
            </Link>
            <Link
              // onClick={() => setValues("50 - +")}
              onClick={() => setValues("no")}
              to={{
                pathname: nextPageRoute,
                search: generatorQuery.get(),
              }}
            >
              No
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

function CTA({ headline, subtitle, paragraph }) {
  const generatorQuery = useGeneratorQuery();

  useEffect(() => {
    // generatorQuery.set(window.location.search);
    sessionStorage.setItem(routes.lander, true);
  }, []);
  return (
    <>
      <div className="hero-container">
        <div className="headline-wrapper">
          <h1>{headline}</h1>
        </div>
        <div className="sub-hero-section">
          <div className="hero-section-wrapper">
            <div className="hero-section-wrapper-content">
              <h2>{subtitle}</h2>
              <p>{paragraph}</p>
            </div>
            {
              //  <div className="hero-sectiom-image">
              //  <img loading="lazy" src={heroimage}></img>
              //  </div>
            }
            <div className="cta">
              <Link
                id="cta-click"
                to={{ pathname: "income", search: generatorQuery.get() }}
              >
                I Want My Card{" "}
                <img loading="lazy" src={"/assets/arrow.svg"} alt=""></img>
              </Link>
              <span>37 Available Slots Left, Act Now!</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function PhoneNumber({ headline, nextPageRoute }) {
  const generatorQuery = useGeneratorQuery();
  const { storeRgbaData } = useRingba();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState("");

  const setValues = (value) => {
    sessionStorage.setItem(RINGBA_STORAGE_KEYS.income, value);

    window._rgba_tags = window._rgba_tags || [];
    window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.income]: value });
  };

  const navigate = useNavigate();

  const onSubmit = async () => {
    try {
      setError(false);
      setLoading(true);
      setSubmitted(true);
      setApiErrorMessage("");
      const { data } = await axios.get(
        "https://wecalllongfunnel.improveourcredit.com?phone=" + phoneNumber
      );
      if (data.valid === false) {
        console.log(data);
        setError(true);
        if (data.message) {
          setApiErrorMessage(data.message);
        }
      } else {
        sessionStorage.setItem(RINGBA_STORAGE_KEYS.mobile, phoneNumber);
        window._rgba_tags = window._rgba_tags || [];
        window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.mobile]: phoneNumber });
        navigate(nextPageRoute);
      }
    } catch (error) {
      setError(true);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onChange = (event) => {
    if (event.target.value.length > 14) return;

    setSubmitted(false);
    const value = event.target.value;

    const rawValue = value.replace(/\D/g, "");

    let formatted = rawValue;
    if (rawValue.length > 3 && rawValue.length <= 6) {
      formatted = `${rawValue.replace(/^(\d{3})(\d+)/g, "($1) $2")}`;
    } else if (rawValue.length > 6) {
      formatted = `${rawValue.replace(/^(\d{3})(\d{3})(\d+)/g, "($1) $2-$3")}`;
    }

    setFormattedPhoneNumber(formatted);
    setPhoneNumber(rawValue);
  };

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(routes.lander))
      return navigate({ pathname: "../", search: generatorQuery.get() });
    sessionStorage.setItem(routes.income, true);
  }, []);

  const validator = () => {
    if (error) return "Phone number is not valid.";
    if (submitted && formattedPhoneNumber.length < 14)
      return "Please enter 10 digit mobile number.";
  };

  return (
    <div className="question-wrapper">
      <div className="headline-wrapper">
        <h1>{headline}</h1>
      </div>
      <div className="question-item">
        <div className="question">
          <h2>Enter your phone number</h2>
          <input
            type="text"
            placeholder="Phone Number"
            onChange={onChange}
            value={formattedPhoneNumber}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSubmit();
              }
            }}
          />
          <em className="error">{apiErrorMessage || validator()}</em>
          <a
            href="javascript:void(0)"
            onClick={onSubmit}
            to={{
              pathname: nextPageRoute,
              search: generatorQuery.get(),
            }}
          >
            {loading ? (
              <PropagateLoader
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "33.5px",
                }}
                color={"#fff"}
                size={10}
              />
            ) : (
              "Submit"
            )}
          </a>
        </div>
      </div>
    </div>
  );
}

function HealthInsurance({ headline, nextPageRoute }) {
  const generatorQuery = useGeneratorQuery();
  const { storeRgbaData } = useRingba();

  const setValues = (value) => {
    sessionStorage.setItem(RINGBA_STORAGE_KEYS.income, value);

    window._rgba_tags = window._rgba_tags || [];
    window._rgba_tags.push({ ["healthInsurance"]: value });
  };

  const navigate = useNavigate();

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(routes.lander))
      return navigate({ pathname: "../", search: generatorQuery.get() });
    sessionStorage.setItem(routes.income, true);
  }, []);
  return (
    <div className="question-wrapper">
      <div className="headline-wrapper">
        <h1>{headline}</h1>
      </div>
      <div className="question-item">
        <div className="question">
          <h2>Do you have health insurance?</h2>
          <Link
            onClick={() => setValues("yes")}
            to={{
              pathname: nextPageRoute,
              search: generatorQuery.get(),
            }}
          >
            Yes
          </Link>
          <Link
            onClick={() => setValues("no")}
            to={{
              pathname: nextPageRoute,
              search: generatorQuery.get(),
            }}
          >
            No
          </Link>
        </div>
      </div>
    </div>
  );
}

function App() {
  const [number, setNumber] = React.useState("");
  const eventID = useEventID();
  const generatorQuery = useGeneratorQuery();

  const [cityStateResponse, setStateCityResponse] = useState({
    city: "",
    state: "",
    country: "",
    zip: "",
  });

  const cityAddress = async () => {
    const options = {};
    try {
      const { data } = await axios.get(
        "https://funnel.improveourcredit.com/ip?key=askdjaslkdjaskjdsla"
      );
      const state = data.subdivisions[0].isoCode;
      const city = data.city.names.en;
      const country = data.country.names.en;
      const postalCode = data.postal.code;

      localStorage.setItem(sessionStorageKeys.zip, postalCode);
      localStorage.setItem(sessionStorageKeys.city, city);
      localStorage.setItem(sessionStorageKeys.state, state);
      setStateCityResponse({ state, city, country, zip: postalCode });

      window._rgba_tags = window._rgba_tags || [];
      window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.zip]: postalCode });
      window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.city]: city });
      window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.state]: state });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    eventID.getEventId();
    cityAddress();
    generatorQuery.set(window.location.search);
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <DefaultPage
              cityStateResponse={cityStateResponse}
              setNumber={setNumber}
            />
          }
        >
          <Route
            index
            element={
              <CTA
                subtitle={``}
                headline={`Americans in ${cityStateResponse.state} could be eligible to save upto $6400* Tax Credit!`}
                paragraph={`Thanks to a new aca program, ${cityStateResponse.state} residents could save upto $6400 in health tax credit.`}
              />
            }
          />
          <Route
            path={ROUTES.income}
            element={
              <Income
                nextPageRoute={"../" + ROUTES.medicareMedicaid}
                headline={`Americans in ${cityStateResponse.state} could be eligible to save upto $6400* Tax Credit!`}
              />
            }
          />
          <Route
            path={ROUTES.medicareMedicaid}
            element={
              <MedicareMedicaid
                nextPageRoute={"../congrats"}
                headline={`Americans in ${cityStateResponse.state} could be eligible to save upto $6400* Tax Credit!`}
              />
            }
          />
          <Route
            path={ROUTES.congrats}
            element={
              <Congrats
                headline={
                  "Congratulations! You could be eligible for $6,400 Health Tax Credit."
                }
                number={number}
              />
            }
          />
        </Route>

        <Route
          path="/lander-1"
          element={
            <QuizLander
              cityStateResponse={cityStateResponse}
              setNumber={setNumber}
            />
          }
        >
          <Route
            index
            element={
              <CTA
                subtitle={``}
                headline={`Americans in ${cityStateResponse.state} have only 3 days to claim their FREE $6400* Subsidy!`}
                paragraph={`Thanks to a new government subsidiary program, ${cityStateResponse.state} residents could get up to $6400* allowance for the everyday expense.`}
              />
            }
          />
          <Route
            path={ROUTES.income}
            element={
              <Income
                nextPageRoute={"../" + ROUTES.medicareMedicaid}
                headline={
                  "Americans Rush To A Newly Announced Premium Care Subsidy This Month!"
                }
              />
            }
          />
          <Route
            path={ROUTES.medicareMedicaid}
            element={
              <MedicareMedicaid
                nextPageRoute={"../congrats"}
                headline={
                  "Americans Rush To A Newly Announced Premium Care Subsidy This Month!"
                }
              />
            }
          />
          <Route
            path={ROUTES.congrats}
            element={
              <Congrats
                headline={
                  "Congratulations! You are eligible for $6,400 Subsidy."
                }
                number={number}
              />
            }
          />
        </Route>

        {/* <Route path="/lander-1" element={<QuizLander cityStateResponse={cityStateResponse} setNumber={setNumber} />}>
          <Route index element={<CTA subtitle={``}  headline={`Americans in ${cityStateResponse.state} have only 3 days to claim their FREE $6400* Subsidy!`} paragraph={`Thanks to a new government subsidiary program, ${cityStateResponse.state} residents could get up to $6400* allowance for the everyday expense.`} />} />
          <Route path={ROUTES.income} element={<Income headline={`Americans in ${cityStateResponse.state} could be eligible to save upto $6400* Tax Credit!`} />} />
          <Route path={ROUTES.medicareMedicaid} element={<MedicareMedicaid headline={`Americans in ${cityStateResponse.state} could be eligible to save upto $6400* Tax Credit!`} />} />
          <Route path={ROUTES.congrats} element={<Congrats headline={'Congratulations! You could be eligible for $6,400 Health Tax Credit.'} number={number} />} />
        </Route> */}
        <Route
          path="/lander-4"
          element={
            <Lander4
              cityStateResponse={cityStateResponse}
              setNumber={setNumber}
            />
          }
        >
          <Route
            index
            element={
              <Lander4CTA
                subtitle={``}
                headline={`Americans in ${cityStateResponse.state} have only 3 days to claim their FREE $6400* Subsidy!`}
                paragraph={`Thanks to a new government subsidiary program, ${cityStateResponse.state} residents could get up to $6400* allowance for the everyday expense.`}
              />
            }
          />
          <Route
            path={ROUTES.income}
            element={
              <Lander4Income
                nextPageRoute={"../" + ROUTES.medicareMedicaid}
                headline={
                  "Americans Rush To A Newly Announced Premium Care Subsidy This Month!"
                }
              />
            }
          />
          <Route
            path={ROUTES.medicareMedicaid}
            element={
              <Lander4MedicareMedicaid
                nextPageRoute={"../congrats"}
                headline={
                  "Americans Rush To A Newly Announced Premium Care Subsidy This Month!"
                }
              />
            }
          />
          <Route
            path={ROUTES.congrats}
            element={
              <Lander4Congrats
                headline={
                  "Congratulations! You are eligible for $6,400 Subsidy."
                }
                number={number}
              />
            }
          />
        </Route>
        <Route
          path="/lander-3"
          element={
            <Lander3
              cityStateResponse={cityStateResponse}
              setNumber={setNumber}
            />
          }
        >
          <Route
            index
            element={
              <Lander3CTA
                subtitle={``}
                headline={`Low-Income ${cityStateResponse.state} Residents Rush to New Advanced Premium Tax Credit in November!`}
                paragraph={
                  "Thanks to a new government subsidiary program, DL residents could get upto $6400 premium tax credit towards your monthly insurance premium."
                }
              />
            }
          />
          <Route
            path={ROUTES.income}
            element={
              <Lander3Income
                nextPageRoute={"../" + ROUTES.medicareMedicaid}
                headline={
                  "Americans Rush To A Newly Announced Premium Care Subsidy This Month!"
                }
              />
            }
          />
          <Route
            path={ROUTES.medicareMedicaid}
            element={
              <Lander3MedicareMedicaid
                nextPageRoute={"../congrats"}
                headline={
                  "Americans Rush To A Newly Announced Premium Care Subsidy This Month!"
                }
              />
            }
          />
          <Route
            path={ROUTES.congrats}
            element={
              <Lander3Congrats
                headline={
                  "Congratulations! You are eligible for $6,400 Subsidy."
                }
                number={number}
              />
            }
          />
        </Route>
        <Route
          path="/lander-2"
          element={
            <DefaultPage
              cityStateResponse={cityStateResponse}
              setNumber={setNumber}
            />
          }
        >
          <Route
            index
            element={
              <CTA
                subtitle={``}
                headline={`Americans in ${cityStateResponse.state} could be eligible to save upto $6400* Tax Credit!`}
                paragraph={`Thanks to a new aca program, ${cityStateResponse.state} residents could save upto $6400 in health tax credit.`}
              />
            }
          />
          <Route
            path={ROUTES.income}
            element={
              <Income
                nextPageRoute={"../" + ROUTES.medicareMedicaid}
                headline={`Americans in ${cityStateResponse.state} could be eligible to save upto $6400* Tax Credit!`}
              />
            }
          />
          <Route
            path={ROUTES.medicareMedicaid}
            element={
              <MedicareMedicaid
                nextPageRoute={"../congrats"}
                headline={`Americans in ${cityStateResponse.state} could be eligible to save upto $6400* Tax Credit!`}
              />
            }
          />
          <Route
            path={ROUTES.congrats}
            element={
              <Congrats
                headline={
                  "Congratulations! You could be eligible for $6,400 Health Tax Credit."
                }
                number={number}
              />
            }
          />
        </Route>

        <Route
          path="/quiz"
          element={
            <QuizLander
              cityStateResponse={cityStateResponse}
              setNumber={setNumber}
            />
          }
        >
          <Route
            index
            element={
              <CTA
                subtitle={``}
                headline={`Americans in ${cityStateResponse.state} could be eligible to save upto $6400* Tax Credit!`}
                paragraph={`Thanks to a new aca program, ${cityStateResponse.state} residents could save upto $6400 in health tax credit.`}
              />
            }
          />
          <Route
            path={ROUTES.income}
            element={
              <Income
                nextPageRoute={"../" + ROUTES.medicareMedicaid}
                headline={`Americans in ${cityStateResponse.state} could be eligible to save upto $6400* Tax Credit!`}
              />
            }
          />
          <Route
            path={ROUTES.medicareMedicaid}
            element={
              <MedicareMedicaid
                nextPageRoute={"../congrats"}
                headline={`Americans in ${cityStateResponse.state} could be eligible to save upto $6400* Tax Credit!`}
              />
            }
          />
          <Route
            path={ROUTES.congrats}
            element={
              <Congrats
                headline={
                  "Congratulations! You could be eligible for $6,400 Health Tax Credit."
                }
                number={number}
              />
            }
          />
        </Route>

        <Route
          path="/lander-78"
          element={
            <QuizLander
              cityStateResponse={cityStateResponse}
              setNumber={setNumber}
            />
          }
        >
          <Route
            index
            element={
              <CTAQuizLander
                subtitle={`Americans in ${cityStateResponse.city}, ${cityStateResponse.state}, have only 3 days to claim their FREE $6400* Subsidy which they can use for Grocery, Rent, Gas and More!`}
                headline={
                  "Americans Rush To A Newly Announced Premium Care Subsidy This Month!"
                }
              />
            }
          />
          <Route
            path={ROUTES.income}
            element={
              <IncomeQuizLander
                setNumber={setNumber}
                nextPageRoute={"../" + ROUTES.medicareMedicaid}
                headline={
                  "Americans Rush To A Newly Announced Premium Care Subsidy This Month!"
                }
              />
            }
          />
          <Route
            path={ROUTES.medicareMedicaid}
            element={
              <MedicareMedicaidQuizLander
                nextPageRoute={"../" + ROUTES.healthInsurance}
                headline={
                  "Americans Rush To A Newly Announced Premium Care Subsidy This Month!"
                }
              />
            }
          />
          <Route
            path={ROUTES.healthInsurance}
            element={
              <HealthInsurance
                nextPageRoute={"../" + ROUTES.phoneNumber}
                headline={
                  "Americans Rush To A Newly Announced Premium Care Subsidy This Month!"
                }
              />
            }
          />
          <Route
            path={ROUTES.phoneNumber}
            element={
              <PhoneNumber
                nextPageRoute={"../congrats"}
                headline={
                  "Americans Rush To A Newly Announced Premium Care Subsidy This Month!"
                }
              />
            }
          />
          <Route
            path={ROUTES.congrats}
            element={
              <CongratsQuizLander
                headline={
                  "Congratulations! You are eligible for $6,400 Subsidy."
                }
                number={number}
              />
            }
          />
        </Route>

        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<TermsAndCondition />} />
      </Routes>
    </BrowserRouter>
  );
}

export const Timer = () => {
  const Ref = useRef(null);
  const [timer, setTimer] = useState("00:00");

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    // const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    setTimer("00:10");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 190);
    return deadline;
  };
  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);
  const onClickReset = () => {
    clearTimer(getDeadTime());
  };

  return <span>{timer}</span>;
};

export default App;
